<template>
  <div class="settlementApproval">
    <el-form ref="form" :rules="formRules" label-width="150px" :model="form" label-position="left">
      <div class="innerBox1" v-show="pages">
        <!-- 标题 -->
        <div class="title">
          <h3>{{accountName}}</h3>
          <h3>统一社会信用代码：{{licenseNumber}}</h3>
        </div>
        <div class="formBox">
          <el-form-item label="营业执照照片" prop="licenseCopy">
            <div class="ItemBox">
              <div class="icon">
                <i class=" el-icon-warning-outline"></i>
              </div>

              <el-upload action="" class="upload-demo" :limit="1" :auto-upload="false" ref="upload"
                :on-change="uploadImages" :class="fileList.length > 0? 'no-image' : '' " list-type="picture-card"
                :file-list="fileList">
                <i class="el-icon-plus"></i>
                <!-- 这里是对应图片的按钮 -->
                <div slot="file" slot-scope="{ file }">
                  <el-image ref="preview" style="height: 115px" class="el-upload-list__item-thumbnail" :src="file.url"
                    :preview-src-list="BigLicenseCopy" alt="" :initial-index=0 />

                  <span class="el-upload-list__item-actions">
                    <span v-if="!file.isShow" class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>


              <!-- <img src="@/assets/示列图片_20250102163515.png" alt=""> -->
              <!-- <el-image style="width: 100px; height: 100px" :src="require('@/assets/示列图片_20250102163515.png')"
                :preview-src-list="[require('@/assets/示列图片_20250102163515.png')]" alt="示例图片" /> -->

            </div>
            <div class="tipsBox" v-if="false">
              <ol>
                <li>1、.场景图片正面拍得目清晰完整，图片不得有遮挡</li>
                <li>2、门店招牌洁晰、招啤名陈攻字可辨迎，门框元馆，且店面显示在艺;若为停车场等大我就点击按单价</li>
              </ol>
            </div>

          </el-form-item>
          <el-form-item label="注册地址" prop="licenseAddress">
            <div class="ipt">
              <el-input v-model="form.licenseAddress" placeholder=""></el-input>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>
          </el-form-item>
          <el-form-item label="营业期限" style="width: 700px;">
            <div class="operatingPeriod">
              <el-col :span="11">
                <el-form-item prop="periodBegin">
                  <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.periodBegin"
                    style="width: 200px;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="line" v-if="!form.periodLongTerm" :span="2">至</el-col>
              <el-col :span="11" v-if="!form.periodLongTerm">
                <el-form-item prop="periodEnd">
                  <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.periodEnd"
                    style="width: 200px;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="11" style="margin-left: 20px;">
                <el-checkbox v-model="form.periodLongTerm" @change="hasChecked">长期有效</el-checkbox>
              </el-col>
            </div>

          </el-form-item>
          <el-form-item label="法人姓名" prop="legalPerson">
            <div class="ipt">
              <el-input v-model="form.legalPerson" placeholder=""></el-input>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>
          </el-form-item>

          <el-form-item label="身份证人像面照片" prop="idCardCopy">
            <div class="ItemBox">
              <div class="icon">
                <i class=" el-icon-warning-outline"></i>
              </div>

              <el-upload action="" class="upload-demo" :limit="1" :auto-upload="false" ref="upload"
                :on-change="uploadImages2" :class="fileList2.length > 0? 'no-image' : '' " list-type="picture-card"
                :file-list="fileList2">
                <i class="el-icon-plus"></i>
                <!-- 这里是对应图片的按钮 -->
                <div slot="file" slot-scope="{ file }">
                  <el-image ref="preview2" style="height: 115px" class="el-upload-list__item-thumbnail" :src="file.url"
                    :preview-src-list="BigIdCardCopy" alt="" :initial-index=0 />

                  <span class="el-upload-list__item-actions">
                    <span v-if="!file.isShow" class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview2(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove2(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>

              <!-- <img src="@/assets/icon/imgError.png" alt=""> -->
            </div>


          </el-form-item>
          <el-form-item label="身份证国徽面照片" prop="idCardNational">
            <div class="ItemBox">
              <div class="icon">
                <i class=" el-icon-warning-outline"></i>
              </div>

              <el-upload action="" class="upload-demo" :limit="1" :auto-upload="false" ref="upload"
                :on-change="uploadImages3" :class="fileList3.length > 0? 'no-image' : '' " list-type="picture-card"
                :file-list="fileList3">
                <i class="el-icon-plus"></i>
                <!-- 这里是对应图片的按钮 -->
                <div slot="file" slot-scope="{ file }">
                  <el-image ref="preview3" style="height: 115px" class="el-upload-list__item-thumbnail" :src="file.url"
                    :preview-src-list="BigIdCardNational" alt="" :initial-index=0 />

                  <span class="el-upload-list__item-actions">
                    <span v-if="!file.isShow" class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview3(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove3(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>

              <!-- <img src="@/assets/icon/imgError.png" alt=""> -->
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、.场景图片正面拍得目清晰完整，图片不得有遮挡</span>
              <span>2、门店招牌洁晰、招啤名陈攻字可辨迎，门框元馆，且店面显示在艺;若为停车场等7</span>
            </div>

          </el-form-item>
          <!-- <el-form-item label="证件持有人姓名" prop="idCardName">
            <div class="ipt">
              <el-input v-model="form.idCardName" placeholder=""></el-input>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>
          </el-form-item> -->
          <el-form-item label="证件号码" prop="idCardNumber">
            <div class="ipt">
              <el-input v-model="form.idCardNumber" placeholder=""></el-input>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>
          </el-form-item>
          <el-form-item label="证件持有人住址" prop="idCardAddress">
            <div class="ipt">
              <el-input v-model="form.idCardAddress" placeholder=""></el-input>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>
          </el-form-item>
          <el-form-item label="证件有效期" style="width: 700px;">
            <div class="operatingPeriod">
              <el-col :span="11">
                <el-form-item prop="cardPeriodBegin">
                  <el-date-picker type="date" placeholder="请选择时间" value-format="yyyy-MM-dd"
                    v-model="form.cardPeriodBegin" style="width: 200px;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2" v-if="!form.cardPeriodLongTerm">至</el-col>
              <el-col :span="11" v-if="!form.cardPeriodLongTerm">
                <el-form-item prop="cardPeriodEnd">
                  <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="form.cardPeriodEnd"
                    style="width: 200px;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="11" style="margin-left: 20px;">
                <el-checkbox v-model="form.cardPeriodLongTerm" @change="hasChecked2">长期有效</el-checkbox>
              </el-col>
            </div>

          </el-form-item>
          <el-form-item label="银行账号" prop="accountNumber">
            <div class="ipt">
              <el-input v-model="form.accountNumber" placeholder=""></el-input>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>

          </el-form-item>
          <el-form-item label="开户银行" prop="accountBank">

            <el-cascader style="width: 190px" ref="address2" v-model="form.accountBank" :props="accountProp"
              :options="accountBankList" @change="regionChange2"></el-cascader>
          </el-form-item>

          <el-form-item label="注册人手机号" prop="mobilePhone">
            <div class="ipt">
              <el-input v-model="form.mobilePhone" placeholder=""></el-input>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>
          </el-form-item>
          <el-form-item label="邮箱" prop="contactEmail">
            <div class="ipt">
              <el-input v-model="form.contactEmail" placeholder=""></el-input>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>
          </el-form-item>
          <div class="btnBox">
            <el-button type="primary" @click="changePages">下一步</el-button>
          </div>
        </div>

      </div>
      <div class="innerBox2" v-show="!pages">
        <div class="formBox">
          <!-- <el-form ref="form" :rules="formRules" label-width="150px" :model="form" label-position="left"> -->
          <el-form-item label="客服电话" prop="servicePhone">
            <div class="ipt">
              <el-input v-model="form.servicePhone" placeholder=""></el-input>
              <div class="icon">
                <i class="el-icon-question"></i>
              </div>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>
          </el-form-item>
          <el-form-item label="线下场所名称" prop="bizStoreName">
            <el-input v-model="form.bizStoreName" placeholder=""></el-input>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>

          </el-form-item>
          <el-form-item label="线下场所省市" prop="bizAddressCode">
            <el-cascader style="width: 190px" ref="address" v-model="form.bizAddressCode" :props="prop"
              :options="proviceArr" :show-all-levels="false" @change="regionChange"></el-cascader>
          </el-form-item>
          <el-form-item label="线下场所地址" prop="bizStoreAddress">
            <el-input v-model="form.bizStoreAddress" placeholder=""></el-input>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>

          </el-form-item>
          <el-form-item label="线下场所门头照片" prop="storeEntrancePic">
            <div class="ItemBox">
              <div class="icon">
                <i class=" el-icon-warning-outline"></i>
              </div>
              <el-upload action="" class="upload-demo" :limit="1" :auto-upload="false" ref="upload"
                :on-change="uploadImages4" :class="storeEntrancePic2.length ? 'no-image' : '' " list-type="picture-card"
                :file-list="storeEntrancePic2">
                <i class="el-icon-plus"></i>
                <!-- 这里是对应图片的按钮 -->
                <div slot="file" slot-scope="{ file }">
                  <el-image ref="preview4" :auto-upload="false" style="height: 115px"
                    class="el-upload-list__item-thumbnail" :src="file.url" :preview-src-list="BigStoreEntrancePic"
                    alt="" :initial-index=0 />

                  <span class="el-upload-list__item-actions">
                    <span v-if="!file.isShow" class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview4(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove4(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <!-- <img src="@/assets/icon/imgError.png" alt=""> -->
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、.场景图片正面拍得目清晰完整，图片不得有遮挡</span>
              <span>2、门店招牌洁晰、招啤名陈攻字可辨迎，门框元馆，且店面显示在艺;若为停车场等7</span>
            </div>

          </el-form-item>
          <el-form-item label="线下场所内部照片" prop="indoorPic">
            <div class="ItemBox">
              <div class="icon">
                <i class=" el-icon-warning-outline"></i>
              </div>

              <el-upload action="" class="upload-demo" :limit="1" :auto-upload="false" ref="upload"
                :on-change="uploadImages5" :class="indoorPic2.length > 0? 'no-image' : '' " list-type="picture-card"
                :file-list="indoorPic2">
                <i class="el-icon-plus"></i>
                <!-- 这里是对应图片的按钮 -->
                <div slot="file" slot-scope="{ file }">
                  <el-image ref="preview5" :auto-upload="false" style="height: 115px"
                    class="el-upload-list__item-thumbnail" :src="file.url" :preview-src-list="BigIndoorPic" alt=""
                    :initial-index=0 />

                  <span class="el-upload-list__item-actions">
                    <span v-if="!file.isShow" class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview5(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove5(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>


              <!-- <img src="@/assets/icon/imgError.png" alt=""> -->

            </div>
            <div class="tipsBox" v-if="false">
              <span>1、.场景图片正面拍得目清晰完整，图片不得有遮挡</span>
              <span>2、门店招牌洁晰、招啤名陈攻字可辨迎，门框元馆，且店面显示在艺;若为停车场等7</span>
            </div>

          </el-form-item>

          <el-form-item label="商户名称" prop="merchantName">
            <div class="ipt">
              <el-input v-model="form.merchantName" placeholder=""></el-input>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>
          </el-form-item>

          <el-form-item label="商户简称" prop="merchantShortname">
            <div class="ipt">
              <el-input v-model="form.merchantShortname" placeholder=""></el-input>
            </div>
            <div class="tipsBox" v-if="false">
              <span>1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务</span>
              <span>2、诘确保电话畅通，以便入驻后平台回拨确认。</span>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="btnBox">
              <el-button type="primary" @click="goBack">上一步</el-button>
              <el-button type="primary" @click="onSubmit">{{title}}</el-button>
            </div>

          </el-form-item>

        </div>

      </div>
    </el-form>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { uploadFile } from "@/api/fileApi";
  import { getyyzz, getsfz } from '@/api/certificate'
  import { saveVXIdentity, selectVXonlineUser, updateVXIdentity, accountBankList } from '@/api/shfw';
  import { getAreaTree } from "@/api/certificate";
  import { removeEmptyChildren } from "@/js/utils/util";
  export default {
    data() {
      //地区选择校验
      var checkArea = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请选择地址"));
        } else {
          setTimeout(() => {
            if (this.areaHasChild) {
              callback(new Error("请选到最后一级"));
            } else {
              callback();
            }
          }, 100);
        }
      };

      return {
        baseUrl: window.globalUrl.HOME_API,
        uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
        title: '提交',
        // uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
        formRules: {
          licenseCopy: [
            { required: true, message: '请提交营业执照照片', trigger: 'change' },
          ],//营业执照
          licenseAddress: [
            { required: true, message: '请输入注册地址', trigger: 'blur' },
          ], //注册地址
          periodBegin: [
            { required: true, message: "请选择生效时间", trigger: "blur" },
          ],      // 营业期限开始时间
          periodEnd: [
            { required: true, message: "请选择失效时间", trigger: "blur" },
          ],//营业期限结束时间
          legalPerson: [
            { required: true, message: '请输入法人姓名', trigger: 'blur' },
          ],//法人姓名
          idCardCopy: [
            { required: true, message: '请提交身份证人像面照片', trigger: 'change' },
          ],// 身份证人像面照片
          idCardNational: [
            { required: true, message: '请提交身份证国徽面照片', trigger: 'change' },
          ], // 身份证国徽面照片
          // idCardName: [
          //   { required: true, message: '请输入证件持有人姓名', trigger: 'blur' },
          // ], // 证件持有人姓名
          idCardNumber: [
            { required: true, message: '请输入证件号码', trigger: 'blur' },
            {
              pattern: /^(?:\d{15}|\d{18}|\d{17}X)$/,
              message: '证件号码格式不正确',
              trigger: 'blur'
            },

          ], // 证件号码
          idCardAddress: [
            { required: true, message: '请输入证件持有人住址', trigger: 'blur' },
          ], // 证件持有人住址
          cardPeriodBegin: [
            { required: true, message: '证件生效时间', trigger: 'blur' },
          ], // 证件有效期-开始时间

          cardPeriodEnd: [
            { required: true, message: '证件失效时间', trigger: 'blur' },
          ], //证件有效期-结束时间

          // idCardName: [
          //   { required: true, message: '请输入证件持有人姓名', trigger: 'blur' },
          // ], // 证件持有人姓名
          // idCardName: [
          //   { required: true, message: '请输入证件持有人姓名', trigger: 'blur' },
          // ], // 证件持有人姓名
          accountNumber: [
            { required: true, message: '请输入证件持有人姓名', trigger: 'blur' },
          ],// 银行账号
          accountBank: [
            { required: true, message: '请选择开户银行', trigger: 'blur' },
          ],// 开户银行

          mobilePhone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
          ],    //  注册人手机号
          contactEmail: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
          ],// 邮箱

          servicePhone: [
            { required: true, message: '请输入客服电话', trigger: 'blur' },
          ], // 客服电话

          bizStoreName: [
            { required: true, message: '请输入线下场所名称', trigger: 'blur' },
          ], // 线下场所名称

          bizAddressCode: [
            { required: true, message: '请选择线下场所省市', validator: checkArea, trigger: "change" },
          ],// 线下场所省市

          bizStoreAddress: [
            { required: true, message: '请输入线下场所地址', trigger: 'change' },
          ], // 线下场所地址

          storeEntrancePic: [
            { required: true, message: '请提交线下场所门头照片', trigger: 'change' },
          ],  // 线下场所门头照片

          indoorPic: [
            { required: true, message: '请提交线下场所内部照片' },
          ], // 线下场所内部照片

          merchantName: [
            { required: true, message: '请输入商户名称', trigger: 'blur' },
          ],   // 商户名称

          merchantShortname: [
            { required: true, message: '请输入商户简称', trigger: 'blur' },
          ]   // 商户简称


        },
        prop: {
          label: "areaName",
          children: "children",
          value: "id",
          checkStrictly: true,
        }, //省市区选择器
        accountProp: {
          label: "label",
          // children: "children",
          value: "remarks",
          // checkStrictly: true,
        },// 开户银行选择器
        form: {
          idDocType: '',//证件类型
          accountNumber: '',//银行账号
          accountBank: '',//开户银行
          licenseAddress: '',//注册地址
          contactName: '',//	经营者姓名/法定代表人
          servicePhone: '',//客服电话
          bizStoreName: '',//线下场所名称
          bizAddressCode: '',//	线下场所省市编码
          bizStoreAddress: '',//线下场所地址
          periodLongTerm: false,//	营业期限是否为长期有效
          periodBegin: '',//有效期开始时间
          periodEnd: '',//有效期结束时间
          // idCardName: '',//证件持有人姓名
          idCardNumber: '',//证件号码
          idCardAddress: '',//证件持有人住址
          cardPeriodBegin: '',//证件有效期开始时间
          cardPeriodEnd: '',//证件有效期结束时间
          cardPeriodLongTerm: false,// 证件有效期
          mobilePhone: '',//注册人手机号
          contactEmail: '',//注册人邮箱
          merchantName: '',//商户名称
          legalPerson: '', //法人姓名
          merchantShortname: '',// 商户简称
          bankAddressCode: '511700',//开户银行省市区编码
          licenseCopy: [],//营业执照照片
          idCardCopy: [],//身份证人面像
          idCardNational: [],//身份证国徽像
          storeEntrancePic: [],//线下场所门头照片
          indoorPic: [],//线下场所内部照片
        },
        accountName: '',//开户人名称
        licenseNumber: '',//统一社会信用代码

        fileList: [],//营业执照-图片拼接地址
        BigLicenseCopy: [],//营业执照大图


        BigIdCardCopy: [],//身份证人面像大图
        fileList2: [], //身份证人面像-图片拼接地址

        fileList3: [],//身份证国徽像-图片拼接地址
        BigIdCardNational: [],//身份证背面大图


        storeEntrancePic2: [],//线下场所门头照片-服务
        BigStoreEntrancePic: [],//线下场所门头照片大图


        indoorPic2: [],//线下场所内部照片-服务
        BigIndoorPic: [],//线下场所内部照片大图

        proviceArr: [],
        dialogVisible: false,
        dialogImageUrl: '',
        pages: true,
        businessCode: '',
        accountBankList: [], // 银行列表

      };
    },
    computed: {
      ...mapGetters(["identityObj"]),
      //是否显示上传
      uploadClass() {
        return this.storeEntrancePic2.length ? 'no-image' : '';
      }
    },
    created() {
      this.getRegions();
      this.getAccountBankList()
    },
    mounted() {
      this.initForm()

    },

    methods: {
      //是否显示上传按钮

      //切换银行时的显示
      regionChange2() {
        let node = this.$refs.address2.getCheckedNodes()[0];
        this.areaHasChild = node.hasChildren;
        this.form.accountBank = node.value;
        // this.form.bizStoreAddress = node.pathLabels.join("");
      },
      //银行列表接口
      async getAccountBankList() {
        let res = await accountBankList()
        this.accountBankList = res.data
        console.log(this.accountBankList, '查看银行列表')
      },
      //初始化表单
      async initForm() {

        if (this.$route.query.Edit == '2') {
          this.title = "修改"
          const res = await selectVXonlineUser()
          let obj = res.data

          this.businessCode = obj.businessCode
          this.form.licenseCopy = [{
            url: obj.licenseCopy // 存储营业执照
          }]
          this.fileList = [{
            url: this.baseUrl + "admin/file/get?ossFilePath=" + obj.licenseCopy
          }]

          // 身份证人像面照片
          this.form.idCardCopy = [
            {
              url: obj.idCardCopy
            }
          ]
          this.fileList2 = [{
            url: this.baseUrl + "admin/file/get?ossFilePath=" + obj.idCardCopy // 存储身份证照片人面像
          }]
          console.log(this.fileList2, '查看身份证人面像照片为什么没有显示')
          //身份证国徽像照片


          this.form.idCardNational = [{
            url: obj.idCardNational
          }]
          this.fileList3 = [{
            url: this.baseUrl + "admin/file/get?ossFilePath=" + obj.idCardNational // 身份证照片国徽像
          }]

          this.form.storeEntrancePic = [{
            url: obj.storeEntrancePic
          }]//线下门头照片-上传

          this.storeEntrancePic2 = [{
            url: this.baseUrl + "admin/file/get?ossFilePath=" + obj.storeEntrancePic //线下场所门头照片
          }]// 线下场所门头照片-显示
          console.log(this.storeEntrancePic2, '这里没有显示嘛')


          this.indoorPic2 = [{
            url: this.baseUrl + "admin/file/get?ossFilePath=" + obj.indoorPic //线下场所内部照片

          }]  // 线下场所内部照片
          this.form.indoorPic = [{
            url: obj.indoorPic
          }]
          this.form.accountNumber = obj.bankCardId //银行账号
          this.form.accountBank = obj.affiliatedBank //开户银行

          this.form.contactName = obj.bossName//法人姓名
          //营业执照接入ocr识别，拿到回显的数据之后在赋值注册地址等
          this.licenseNumber = obj.licenseNumber
          this.accountName = obj.accountName
          // obj.realName
          this.merchantName = obj.realName
          this.form.accountNumber = obj.accountNumber//银行账号
          this.form.accountBank = obj.accountBank//开户银行
          this.form.licenseAddress = obj.licenseAddress //注册地址
          this.form.mobilePhone = obj.mobilePhone//注册人手机号
          this.form.contactEmail = obj.contactEmail//注册人邮箱
          this.form.contactName = obj.contactName// 经营者姓名 / 法定代表人
          this.form.servicePhone = obj.servicePhone// 客服电话
          this.form.bizStoreName = obj.bizStoreName// 线下场所名称
          this.form.bizAddressCode = obj.bizAddressCode// 线下场所省市
          this.form.bizStoreAddress = obj.bizStoreAddress  // 线下场所地址

          // 营业期限
          this.form.periodBegin = obj.periodBegin
          this.form.periodEnd = obj.periodEnd
          this.form.periodLongTerm = obj.periodLongTerm == 'true' ? true : false,
            // 商户名称
            this.form.merchantName = obj.merchantName
          //法人姓名
          this.form.legalPerson = obj.legalPerson
          //商户简称
          this.form.merchantShortname = obj.merchantShortname
          // this.form.idCardName = obj.idCardName//证件持有人姓名
          this.form.idCardNumber = obj.idCardNumber//证件号码
          this.form.idCardAddress = obj.idCardAddress//证件持有人住址
          // 证件有效期
          this.form.cardPeriodBegin = obj.cardPeriodBegin
          this.form.cardPeriodEnd = obj.cardPeriodEnd
          this.form.cardPeriodLongTerm = obj.cardPeriodLongTerm == "true" ? true : false
        } else {
          //提交申请
          this.title = "提交申请"
          //营业执照照片
          this.form.licenseCopy = [{
            url: this.identityObj.businessLicense // 存储营业执照
          }]
          console.log(this.form.licenseCopy, '查看这是什么')
          this.fileList = [{
            url: this.baseUrl + "admin/file/get?ossFilePath=" + this.identityObj.businessLicense
          }]

          // 身份证人像面照片
          this.form.idCardCopy = [
            {
              url: this.identityObj.userCardFrontImg
            }
          ]
          console.log(this.form.idCardCopy, '查看这是什么')
          this.fileList2 = [{
            url: this.baseUrl + "admin/file/get?ossFilePath=" + this.identityObj.userCardFrontImg // 存储营业执照
          }]


          this.form.idCardNational = [{
            url: this.identityObj.userCardBackImg
          }]
          this.fileList3 = [{
            url: this.baseUrl + "admin/file/get?ossFilePath=" + this.identityObj.userCardBackImg // 存储营业执照
          }]


          this.form.accountNumber = this.identityObj.bankCardId //银行账号
          this.form.accountBank = this.identityObj.affiliatedBank //开户银行

          this.form.contactName = this.identityObj.bossName//法人姓名
          //营业执照接入ocr识别，拿到回显的数据之后在赋值注册地址等
          this.licenseNumber = this.identityObj.identityCode
          this.accountName = this.identityObj.realName
          this.form.merchantName = this.identityObj.realName
          this.form.legalPerson = this.identityObj.bossName  //法人姓名
          // console.log(this.merchantName, '查看这里没有吗，')
        }



      },
      // 获取行政区树
      async getRegions() {

        let param = {
          level: "3",
          // parentCode:this.topSelectAreacode
        };
        let res = await getAreaTree(param);
        // console.log("行政区",res);
        let { code, data, msg } = res;
        if (code == 0) {
          this.proviceArr = removeEmptyChildren(data);
        } else {
          this.$message.error(msg);
        }
      },
      //上传图片-营业执照
      //TODO营业知道的ocr识别
      async uploadImages(file) {
        let param = new FormData();
        param.append("catalog", "register");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        // console.log(res, '查看res')
        if(res.code==0){
          let FilesName = res.data.sourceFileName;
          let url = res.data.url;
          let item =
          {
            url: '',
            FilesName: ''
          }
          let item2 = {
            url: '',
            FilesName: ''
          }

          // console.log(FilesName, url,)
          item.url = url
          item.FilesName = FilesName
          item2.url = this.baseUrl + 'admin/file/get?ossFilePath=' + url
          item2.FilesName = FilesName
          this.form.licenseCopy = [item];
          this.fileList = [item2]
          if (res.code == 0) {
            let res1 = await getyyzz({
              identity: res.data.thumbnailUrl ? res.data.thumbnailUrl : res.data.url,
            })
            if (res1.code == 0) {

              this.form.licenseAddress = this.form.licenseAddress || res1.data.result.detail.addr
              this.form.legalPerson = this.form.legalPerson || res1.data.result.detail.legal_rep
            }
               this.$refs.form.clearValidate('licenseCopy'); // 假设licenseAddress是需要清除校验的字段
          }
        }


      },
      //上传图片-身份证人像
      async uploadImages2(file) {
        let param = new FormData();
        param.append("catalog", "sfz");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        console.log(res, '查看res')
        let FilesName = res.data.sourceFileName;
        let url = res.data.url;
        let item =
        {
          url: '',
          FilesName: ''
        }
        let item2 = {
          url: '',
          FilesName: ''
        }

        // console.log(FilesName, url,)
        item.url = url
        item.FilesName = FilesName
        item2.url = this.baseUrl + 'admin/file/get?ossFilePath=' + url
        item2.FilesName = FilesName
        this.form.idCardCopy = [item];
        this.fileList2 = [item2]
        if (res.code == 0) {
          // console.log(res.data.url, '查看res.data.thumbnailUrl')
          let res1 = await getsfz({
            identity: res.data.url
          })
          console.log(res1.data.result[0].detail, '查看res1.data.result[0].detail')
          // this.form.idCardName = this.form.idCardName || res1.data.result[0].detail.name
          this.form.idCardNumber = this.form.idCardNumber || res1.data.result[0].detail.idn
          this.form.idCardAddress = this.form.idCardAddress || res1.data.result[0].detail.addr
          // console.log("🚀 ~ uploadImages2 ~ res1.data.result[0].detail.name:", res1.data.result[0].detail.name)
          this.$refs.form.clearValidate('idCardCopy'); // 假设licenseAddress是需要清除校验的字段
        }
      },
      //上传图片-身份证背面
      async uploadImages3(file) {
        let param = new FormData();
        param.append("catalog", "shfw");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        console.log(res, '查看res')
        if(res.code==0){
          let FilesName = res.data.sourceFileName;
          let url = res.data.url;
          let item =
          {
            url: '',
            FilesName: ''
          }
          let item2 = {
            url: '',
            FilesName: ''
          }

          console.log(FilesName, url,)
          item.url = url
          item.FilesName = FilesName
          item2.url = this.baseUrl + 'admin/file/get?ossFilePath=' + url
          item2.FilesName = FilesName
          this.form.idCardNational = [item];
          // this.fileList3.url = this.baseUrl + 'admin/file/get?ossFilePath=' + url
          // this.fileList3.FilesName = FilesName
          this.fileList3 = [item2]
            this.$refs.form.clearValidate('idCardNational'); // 假设licenseAddress是需要清除校验的字段
        }

      },
      //上传图片-线下场所门头照片
      async uploadImages4(file) {
        let param = new FormData();
        param.append("catalog", "shfw");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        if(res.code==0){
          let FilesName = res.data.sourceFileName;
          let url = res.data.url;

          let item =
          {
            url: '',
            FilesName: ''
          }
          let item2 = {
            url: '',
            FilesName: ''
          }
          item.url = url
          item.FilesName = FilesName
          item2.url = this.baseUrl + 'admin/file/get?ossFilePath=' + url
          item2.FilesName = FilesName
          // newList.push(item)
          this.form.storeEntrancePic = [item];
          console.log(this.form.storeEntrancePic, '查看图片')
          this.storeEntrancePic2 = [item2]
          this.$refs.form.clearValidate('storeEntrancePic'); // 假设licenseAddress是需要清除校验的字段
        }


      },

      //上传图片-线下场所内部照片
      async uploadImages5(file) {
        let param = new FormData();
        param.append("catalog", "shfw");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        if(res.code==0){
           console.log(res, '查看res')
        let FilesName = res.data.sourceFileName;
        let url = res.data.url;
        let item =
        {
          url: '',
          FilesName: ''
        }
        let item2 = {
          url: '',
          FilesName: ''
        }
        // console.log(FilesName, url,)
        item.url = url
        item.FilesName = FilesName
        // newList.push(item)
        // console.log(newList, '查看newList')
        this.form.indoorPic = [item];
        // for (let i = 0; i < newList.length; i++) {
        //   let changeItem = {
        //     FilesName: this.newList[i].FilesName,
        //     url: this.baseUrl + 'admin/file/get?ossFilePath=' + newList[i].url
        //   }
        //   this.indoorPic2.push(changeItem)
        // }
        // this.indoorPic2.url = this.baseUrl + 'admin/file/get?ossFilePath=' + url
        // this.indoorPic2.FilesName = FilesName

        item2.url = this.baseUrl + 'admin/file/get?ossFilePath=' + url
        item2.FilesName = FilesName
        this.indoorPic2 = [item2]
        // console.log(this.indoorPic2, '查看图片')
        // console.log(this.indoorPic, '查看图片')
          this.$refs.form.clearValidate('indoorPic'); // 假设licenseAddress是需要清除校验的字段
        }

      },
      //预览照片-营业照片
      handlePictureCardPreview(file) {
        this.BigLicenseCopy = []
        this.BigLicenseCopy.push(file.url)
        this.$nextTick(() => {
          this.$refs.preview.clickHandler();
        });

      },

      //预览照片-身份证人像
      handlePictureCardPreview2(file) {
        this.BigIdCardCopy = []
        this.BigIdCardCopy.push(file.url)
        console.log(this.BigIdCardCopy)
        this.$nextTick(() => {
          this.$refs.preview2.clickHandler();
        });

      },
      //预览照片-身份证背面
      handlePictureCardPreview3(file) {
        this.BigIdCardNational = []
        this.BigIdCardNational.push(file.url)
        console.log(this.BigIdCardNational)
        this.$nextTick(() => {
          this.$refs.preview3.clickHandler();
        });

      },
      // 预览线下场所内部照片
      handlePictureCardPreview5(file) {
        this.BigIndoorPic = []
        this.BigIndoorPic.push(file.url)
        console.log(this.BigIndoorPic)
        this.$nextTick(() => {
          this.$refs.preview5.clickHandler();
        });
      },

      // 线下场所门头照片-预览
      handlePictureCardPreview4(file) {
        this.BigStoreEntrancePic = []
        this.BigStoreEntrancePic.push(file.url)

        this.$nextTick(() => {
          this.$refs.preview4.clickHandler();
        });
      },



      //删除照片-营业照片
      handleRemove(file) {
        console.log(file, '查看删除的图片')
        // this.fileList.splice(this.form.licenseCopy.indexOf(file), 1);
        this.fileList = []
        this.form.licenseCopy = []
        this.BigLicenseCopy = []
      },
      //删除照片-身份证背面
      handleRemove3(file) {
        console.log(file, '查看删除的图片')
        // this.fileList3.splice(this.form.idCardNational.indexOf(file), 1);
        this.fileList3 = []
        this.form.idCardNational = []
        this.BigIdCardNational = []
      },

      //线下场所门头照片-删除
      handleRemove4(file) {

        this.storeEntrancePic2 = []
        this.form.storeEntrancePic = []
        this.BigStoreEntrancePic = []
      },
      //删除照片-身份证人像
      handleRemove2(file) {
        console.log(file, '查看删除的图片')
        // this.fileList2.splice(this.form.idCardCopy.indexOf(file), 1);
        this.fileList2 = []
        this.form.idCardCopy = []
        this.BigIdCardCopy = []
      },

      // 删除线下场所内部照片
      handleRemove5(file) {
        console.log(file, '查看删除的图片')
        // this.indoorPic2.splice(this.indoorPic.indexOf(file), 1);
        this.indoorPic2 = []
        this.form.indoorPic = []
        this.BigIndoorPic = []
      },






      // // 通用图片预览方法
      // handlePreview(file, previewList, refName) {
      //   if (!file || !file.url) {
      //     this.$message.error('图片无效，无法预览');
      //     return;
      //   }

      //   // 清空数组，确保只预览当前图片
      //   this[previewList] = [file.url];

      //   // 触发预览
      //   this.$refs[refName].clickHandler();
      // },
      // // 预览营业执照图片
      // handlePictureCardPreview(file) {
      //   this.handlePreview(file, 'BigLicenseCopy', 'preview');
      // },

      // // 预览身份证人像图片
      // handlePictureCardPreview2(file) {
      //   this.handlePreview(file, 'BigIdCardCopy', 'preview2');
      // },

      // // 预览身份证背面图片
      // handlePictureCardPreview3(file) {
      //   this.handlePreview(file, 'BigIdCardNational', 'preview3');
      // },

      // // 预览线下场所门头照片
      // handlePictureCardPreview4(file) {
      //   this.handlePreview(file, 'BigStoreEntrancePic', 'preview4');
      // },

      // // 预览线下场所内部照片
      // handlePictureCardPreview5(file) {
      //   this.handlePreview(file, 'BigIndoorPic', 'preview5');
      // },

      // // 通用图片上传方法
      // async uploadImage(file, formField, fileListField) {
      //   const param = new FormData();
      //   param.append('catalog', 'shfw');
      //   param.append('file', file.raw);

      //   try {
      //     const res = await uploadFile(param);
      //     const { sourceFileName: FilesName, url } = res.data;

      //     const item = {
      //       url,
      //       FilesName,
      //     };

      //     const item2 = {
      //       url: `${this.baseUrl}admin/file/get?ossFilePath=${url}`,
      //       FilesName,
      //     };

      //     // 更新表单字段
      //     this.form[formField] = [item];
      //     // 更新文件列表
      //     this[fileListField] = [item2];
      //   } catch (error) {
      //     console.error('图片上传失败:', error);
      //     this.$message.error('图片上传失败，请稍后重试');
      //   }
      // },
      // // 上传营业执照图片
      // async uploadImages(file) {
      //   await this.uploadImage(file, 'licenseCopy', 'fileList');
      // },

      // // 上传身份证人像图片
      // async uploadImages2(file) {
      //   await this.uploadImage(file, 'idCardCopy', 'fileList2');
      // },

      // // 上传身份证背面图片
      // async uploadImages3(file) {
      //   await this.uploadImage(file, 'idCardNational', 'fileList3');
      // },

      // // 上传线下场所门头照片
      // async uploadImages4(file) {
      //   await this.uploadImage(file, 'storeEntrancePic', 'storeEntrancePic2');
      // },

      // // 上传线下场所内部照片
      // async uploadImages5(file) {
      //   await this.uploadImage(file, 'indoorPic', 'indoorPic2');
      // },

      // // 通用删除图片方法
      // handleRemove(file, fileListField, formField, previewListField) {
      //   if (!file) {
      //     this.$message.error('删除失败，文件无效');
      //     return;
      //   }

      //   // 清空文件列表
      //   this[fileListField] = [];
      //   // 清空表单字段
      //   this.form[formField] = [];
      //   // 清空预览列表
      //   this[previewListField] = [];
      // },

      // // 删除营业执照图片
      // handleRemove(file) {
      //   this.handleRemove(file, 'fileList', 'licenseCopy', 'BigLicenseCopy');
      // },

      // // 删除身份证人像图片
      // handleRemove2(file) {
      //   this.handleRemove(file, 'fileList2', 'idCardCopy', 'BigIdCardCopy');
      // },

      // // 删除身份证背面图片
      // handleRemove3(file) {
      //   this.handleRemove(file, 'fileList3', 'idCardNational', 'BigIdCardNational');
      // },

      // // 删除线下场所门头照片
      // handleRemove4(file) {
      //   this.handleRemove(file, 'storeEntrancePic2', 'storeEntrancePic', 'BigStoreEntrancePic');
      // },

      // // 删除线下场所内部照片
      // handleRemove5(file) {
      //   this.handleRemove(file, 'indoorPic2', 'indoorPic', 'BigIndoorPic');
      // },

      // 切换线下场所地址
      regionChange() {
        let node = this.$refs.address.getCheckedNodes()[0];
        this.areaHasChild = node.hasChildren;
        console.log(node.hasChildren);
        console.log(this.areaHasChild, "切换行政区");
        console.log(this.identityObj.areaCode);
        this.form.bizAddressCode = node.value;
        this.form.bizStoreAddress = node.pathLabels.join("");

      },

      //切换书写页
      changePages() {
        this.pages = !this.pages
      },

      //退回上一页
      goBack() {
        this.pages = !this.pages
      },

      //营业期限-长期时删除末尾时间
      hasChecked(val) {
        console.log(val, '这里进行改变了')
        if (val) {
          this.form.periodEnd = ''
        }
      },
      //证件有效期-长期时删除末尾时间
      hasChecked2(val) {
        if (val) {
          this.form.cardPeriodEnd = ''
        }
      },
      //提交审批
      async onSubmit() {
        // let obj = this.form
        // let params = {
        //   ...obj,
        //   licenseNumber: this.licenseNumber,
        //   accountName: this.accountName
        // }
        // console.log(params, '查看params')
console.log(this.form.idCardNational,'查看这是什么')
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            if (this.$route.query.Edit == '2') {

              let obj = JSON.parse(JSON.stringify(this.form))

              // obj.licenseCopy = this.form.licenseCopy[0].url
              // obj.idCardCopy = this.form.idCardCopy[0].url
              // obj.idCardNational = this.form.idCardNational[0].url
              // obj.storeEntrancePic = this.form.storeEntrancePic[0].url
              // obj.indoorPic = this.form.indoorPic[0].url
              // 处理图片字段，确保不为空
              const imageFields = ['licenseCopy', 'idCardCopy', 'idCardNational', 'storeEntrancePic', 'indoorPic'];
              imageFields.forEach(field => {
                if (obj[field] && obj[field][0] && obj[field][0].url) {
                  obj[field] = obj[field][0].url;
                } else {
                  obj[field] = ''; // 如果为空，设置为空字符串
                }
              });
              let params = {
                ...obj,
                licenseNumber: this.licenseNumber,
                // accountName: this.accountName,
                businessCode: this.businessCode
              }
              console.log(params, '查看params')
              let res = await updateVXIdentity(params)
              if (res.code == 0) {
                this.$message.success(res.msg)
                // this.initForm()
                this.$router.go(-1); // 返回上一级路由
              } else {
                this.$message.error(res.msg)
              }
            } else {

              let obj = JSON.parse(JSON.stringify(this.form))
              // console.log(obj, '查看obj的值')
              // obj.licenseCopy = this.form.licenseCopy[0].url
              // obj.idCardCopy = this.form.idCardCopy[0].url
              // obj.idCardNational = this.form.idCardNational[0].url
              // obj.storeEntrancePic = this.form.storeEntrancePic[0].url
              // obj.indoorPic = this.form.indoorPic[0].url
              const imageFields = ['licenseCopy', 'idCardCopy', 'idCardNational', 'storeEntrancePic', 'indoorPic'];
              imageFields.forEach(field => {
                if (obj[field] && obj[field][0] && obj[field][0].url) {
                  obj[field] = obj[field][0].url;
                } else {
                  obj[field] = ''; // 如果为空，设置为空字符串
                }
              });
              let params = {
                ...obj,
                licenseNumber: this.licenseNumber,
                accountName: this.accountName,
                // businessCode: this.businessCode,
                // accountName: this.accountName
              }
              console.log(params, '查看params')
              let res = await saveVXIdentity(params)
              if (res.code == 0) {
                this.$message.success("保存成功");
                // this.$message.success(res.msg)
                this.$router.go(-1); // 返回上一级路由
              } else {
                this.$message.error('保存失败,请联系管理员')
              }
            }
          } else {
            this.$message.error("请完善发布信息");
          }
        })
      }

    },
  };
</script>

<style lang="less" scoped>
  .settlementApproval {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;

    .innerBox1 {
      width: 100%;
      height: 100%;
      /* border: solid 1px black; */
      /* margin-left: 50px; */
      margin-top: 50px;

      .title {
        display: flex;

        h3 {
          margin-left: 100px;
        }
      }

      .formBox {
        width: 80%;
        margin-left: 100px;

        .operatingPeriod {
          display: flex;
        }

        /deep/.el-form-item {
          width: 100%;

          .el-input {
            width: 600px;
            height: auto;
          }

          .el-form-item__label {
            width: 100px;
            height: auto;
          }

          .el-form-item__content {
            width: 100%;

            .el-select {
              width: 100%;
              height: 100%;
            }
          }

          /deep/ .el-input {
            width: 700px;
            height: 30px;
          }



        }

        .ipt {
          display: flex;

          .icon {
            height: auto;
            margin-left: 20px;

          }
        }

        .tipsBox {
          display: flex;
          flex-direction: column;
          color: #ccc;



        }

        .ItemBox {
          width: 100%;
          display: flex;

          .icon {
            display: flex;
            align-items: center;
            height: auto;
            margin-right: 20px;
          }

          /deep/.el-upload {
            width: 100px;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          /deep/.upload-demo {
            display: flex;

            .el-upload-list__item {
              width: 100px;
              height: 100px;

              img {
                width: 100px;
                height: 100px;
              }

            }
          }

          img {
            width: 100px;
            height: 100px;
            margin-left: 20px;
          }
        }

        .btnBox {
          display: flex;
          justify-content: space-around;
          margin-bottom: 50px;
        }

      }
    }

    .innerBox2 {
      width: 100%;
      height: 100%;
      margin-left: 100px;
      margin-top: 50px;

      /deep/.el-form-item {
        width: 100%;

        .operatingPeriod {
          display: flex;
        }

        .el-input {
          width: 700px;
          height: auto;
        }

        .el-form-item__label {
          width: 150px;
          height: auto;
        }

        /deep/ .el-input {
          width: 700px;
          height: 20px;
        }

        .el-form-item__label {
          width: 150px;
          height: 30px;
        }

      }

      .ipt {
        display: flex;

        .icon {
          height: auto;
          margin-left: 20px;

        }
      }

      .tipsBox {
        display: flex;
        flex-direction: column;
        color: #ccc;

        span {
          display: block;
        }
      }

      .ItemBox {
        display: flex;

        .icon {
          display: flex;
          align-items: center;
          height: auto;
          margin-right: 20px;
        }

        /deep/.el-upload {
          width: 100px;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        /deep/.upload-demo {
          display: flex;

          .el-upload-list__item {
            width: 100px;
            height: 100px;

            img {
              width: 100px;
              height: 100px;
            }

          }
        }

        img {
          width: 100px;
          height: 100px;
          margin-left: 20px;
        }
      }

      .btnBox {
        display: flex;
        justify-content: space-around;
      }
    }
  }

  .no-image {
    /deep/.el-upload {
      display: none !important;
    }
  }

  /* /deep/.el-upload {
    display: none !important;


  }

  /deep/.uploadDisabled {
    display: none !important;

  } */
</style>